import React, { useState, useRef, useEffect } from 'react';
import {
    motion,
    useViewportScroll,
    useTransform,
    useCycle,
    AnimatePresence
} from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import {
    Flex,
    Button,
    Tooltip,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import {
    tooltipAnimation,
    tooltipDownAnimation
} from './sharedCode/components/customTheme';

const variants = {
    over: {
        transition: { staggerChildren: 0.07, delayChildren: 0 }
    },
    out: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
};

const titleVar = {
    over: {
        y: -8,
        opacity: 0,
        transition: { duration: 0.1 }
    },
    out: {
        y: 0,
        opacity: 1,
        transition: { duration: 0.1 }
    }
};

const rowVar = {
    out: {
        y: 0
    },
    over: {
        y: -8
    }
};

const itemsVar = {
    out: {
        //y: 0,
        //x: 0,
        //scale: 1,
        background: '#F4F5F7',
        boxShadow:
            '0 0px 0px 0px rgba(0,0,0,0.0), 0 0px 0px 0px rgba(0,0,0,0.00), inset 0 0 0px 0 #E5EAF3, inset 0px 0px 0px 0 #D5DAE5, inset 0px 0px 0px 0 rgba(0,0,0,0.0)',
        transition: {
            y: { stiffness: 1000, velocity: -100 }
        }
    },
    over: {
        //y: -16,
        //x: 0,
        //scale: 1.25,
        background: '#FBFCFE',
        boxShadow:
            '0 8px 16px -6px rgba(196,199,206,0.50), 0 44px 54px -26px rgba(194,200,206,0.80), inset 0 0 6px 0 #E5EAF3, inset -2px -2px 1px 0 #D5DAE5, inset 2px 2px 3px 0 rgba(255,255,255,0.17)',
        opacity: 1,
        transition: {
            y: { stiffness: 1000 }
        }
    }
};

const itemsVarBlue = {
    out: {
        //y: 0,
        //x: 0,
        //scale: 1,
        background: '#C4E8E6',
        boxShadow:
            '0 0px 0px 0px rgba(0,0,0,0.0), 0 0px 0px 0px rgba(0,0,0,0.00), inset 0 0 0px 0 #E5EAF3, inset 0px 0px 0px 0 #D5DAE5, inset 0px 0px 0px 0 rgba(0,0,0,0.0)',
        transition: {
            y: { stiffness: 1000, velocity: -100 }
        }
    },
    over: {
        //y: -16,
        //x: 0,
        //scale: 1.25,
        background: '#FFF',
        boxShadow:
            '0 8px 16px -6px rgba(9,40,74,0.40), 0 44px 54px -26px rgba(9,40,74,0.70), inset 0 0 6px 0 #EFF2F8, inset -2px -2px 1px 0 #EAECF2, inset 2px 2px 3px 0 rgba(255,255,255,0.17)',
        opacity: 1,
        transition: {
            y: { stiffness: 1000 }
        }
    }
};

const Browsers = ({ browsers, hover, blueBg }) => {
    const [hoverItem, setHover] = useState(false);

    return (
        <>
            <motion.div
                //variants={rowVar}
                className="columns is-centered is-multiline"
                variants={variants}
            >
                {browsers.map((item, index) => (
                    <a
                        href={item.url}
                        key={item.id}
                        alt={item.alt}
                        aria-label={item.alt}
                    >
                        <div
                            className="column is-narrow has-text-centered"
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <motion.div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: 'auto'
                                }}
                            >
                                <motion.div
                                    /* onClick={() => {
                    location.href = item.url;
                  }} */
                                    whileHover={{}}
                                    whileTap={{ scale: 0.96, y: 2 }}
                                    variants={
                                        blueBg === 'blueBg'
                                            ? itemsVarBlue
                                            : itemsVar
                                    }
                                    layout
                                    onHoverStart={(e) => setHover(item.id)}
                                    onHoverEnd={(e) => setHover(false)}
                                    style={{
                                        //border: item.name === "Chrome" ? "2px solid #0078FF" : "none",
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderRadius: '16px',
                                        padding: '12px',
                                        cursor: 'pointer'
                                        //overflow: "hidden",
                                        //width: hoverItem === item.id ? "auto" : "60px",
                                        //width: "60px",
                                    }}
                                >
                                    <motion.div layout>
                                        <Image
                                            src={item.image}
                                            width={36}
                                            height={36}
                                            alt={item.name}
                                        ></Image>
                                    </motion.div>
                                    {hoverItem === item.id && (
                                        <motion.div
                                            layout
                                            initial={{ opacity: 0, x: 8 }}
                                            animate={{ opacity: 1, x: 0 }}
                                            transition={{
                                                duration: 0.1,
                                                delay: 0.15
                                            }}
                                        >
                                            <p
                                                style={{
                                                    margin: 'auto',
                                                    fontWeight: 'bold',
                                                    fontSize: '18px',
                                                    marginLeft: '16px',
                                                    marginRight: '24px',
                                                    color: '#04184a'
                                                }}
                                            >
                                                {item.name}
                                            </p>
                                        </motion.div>
                                    )}
                                </motion.div>
                            </motion.div>
                        </div>
                    </a>
                ))}
            </motion.div>
        </>
    );
};

function OtherBrowser(props) {
    const [hover, toggleHover] = useState(false);

    return (
        <>
            <motion.div
                onHoverStart={(e) => toggleHover(true)}
                onHoverEnd={(e) => toggleHover(false)}
                onMouseLeave={(e) => toggleHover(false)}
                animate={hover ? 'over' : 'out'}
                style={{
                    display: 'inline-block',
                    padding: '0px 16px 4px 16px'
                }}
            >
                <motion.div
                    //variants={titleVar}
                    style={{
                        fontWeight: '400',
                        color: props.type === 'blueBg' ? '#FFF' : '#687482',
                        fontSize: '16px',
                        marginTop:
                            props.mobileAppsOnly || props.all ? '24px' : '48px',
                        marginBottom: '12px',
                        cursor: 'default'
                    }}
                ></motion.div>
                <Browsers
                    browsers={
                        props.mobileAppsOnly
                            ? [
                                  {
                                      name: 'App store',
                                      alt: 'App store(iOS - iPhone/iPad)',
                                      image: '/browsers/app_store_icon.png',
                                      width: '36',
                                      id: '4',
                                      url: 'https://apps.apple.com/se/app/tabextend/id6448757608'
                                  },
                                  {
                                      name: 'Play store',
                                      alt: 'Google Play store(Android)',
                                      image: '/browsers/play_store_icon.png',
                                      width: '36',
                                      id: '5',
                                      url: 'https://play.google.com/store/apps/details?id=com.aconiti.tabextend'
                                  }
                              ]
                            : props.all
                            ? [
                                  {
                                      name: 'Chrome',
                                      alt: 'Chrome store',
                                      image: '/browsers/Chrome_logo.png',
                                      width: '36',
                                      id: '1',
                                      url: 'https://chrome.google.com/webstore/detail/tabextend/ffikidnnejmibopbgbelephlpigeniph'
                                  },
                                  {
                                      name: 'Edge',
                                      alt: 'Edge Add-ons',
                                      image: '/browsers/Edge_logo.png',
                                      width: '36',
                                      id: '2',
                                      url: 'https://microsoftedge.microsoft.com/addons/detail/tabextend/afddlgcjhdhkgimldgmppegmnbmogolg'
                                  },
                                  {
                                      name: 'Brave',
                                      alt: 'Chrome store',
                                      image: '/browsers/Brave_logo.png',
                                      width: '36',
                                      id: '3',
                                      url: 'https://chrome.google.com/webstore/detail/tabextend/ffikidnnejmibopbgbelephlpigeniph'
                                  }
                              ]
                            : [
                                  {
                                      name: 'Edge',
                                      alt: 'Edge Add-ons',
                                      image: '/browsers/Edge_logo.png',
                                      width: '36',
                                      id: '2',
                                      url: 'https://microsoftedge.microsoft.com/addons/detail/tabextend/afddlgcjhdhkgimldgmppegmnbmogolg'
                                  },
                                  {
                                      name: 'Brave',
                                      alt: 'Chrome store',
                                      image: '/browsers/Brave_logo.png',
                                      width: '36',
                                      id: '3',
                                      url: 'https://chrome.google.com/webstore/detail/tabextend/ffikidnnejmibopbgbelephlpigeniph'
                                  },
                                  {
                                      name: 'App store',
                                      alt: 'App store(iOS - iPhone/iPad)',
                                      image: '/browsers/app_store_icon.png',
                                      width: '36',
                                      id: '4',
                                      url: 'https://apps.apple.com/se/app/tabextend/id6448757608'
                                  },
                                  {
                                      name: 'Play store',
                                      alt: 'Google Play store(Android)',
                                      image: '/browsers/play_store_icon.png',
                                      width: '36',
                                      id: '5',
                                      url: 'https://play.google.com/store/apps/details?id=com.aconiti.tabextend'
                                  }
                              ]
                    }
                    hover={hover}
                    blueBg={props.type}
                />
            </motion.div>
        </>
    );
}

export default OtherBrowser;

export const DashboardLinksRow = ({ type }) => {
    const mobile = [
        {
            name: 'App store',
            alt: 'App store(iOS - iPhone/iPad)',
            image: '/browsers/app_store_icon.png',
            width: '36',
            id: '4',
            url: 'https://apps.apple.com/se/app/tabextend/id6448757608'
        },
        {
            name: 'Play store',
            alt: 'Google Play store(Android)',
            image: '/browsers/play_store_icon.png',
            width: '36',
            id: '5',
            url: 'https://play.google.com/store/apps/details?id=com.aconiti.tabextend'
        }
    ];

    const web = [
        {
            name: 'Chrome',
            alt: 'Chrome store',
            image: '/browsers/Chrome_logo.png',
            width: '36',
            id: '1',
            url: 'https://chrome.google.com/webstore/detail/tabextend/ffikidnnejmibopbgbelephlpigeniph'
        },
        {
            name: 'Edge',
            alt: 'Edge Add-ons',
            image: '/browsers/Edge_logo.png',
            width: '36',
            id: '2',
            url: 'https://microsoftedge.microsoft.com/addons/detail/tabextend/afddlgcjhdhkgimldgmppegmnbmogolg'
        },
        {
            name: 'Brave',
            alt: 'Chrome store',
            image: '/browsers/Brave_logo.png',
            width: '36',
            id: '3',
            url: 'https://chrome.google.com/webstore/detail/tabextend/ffikidnnejmibopbgbelephlpigeniph'
        }
    ];

    let data = type === 'mobile' ? mobile : web;

    return (
        <Flex gap="12px">
            {data.map((item, index) => (
                <Tooltip
                    key={index}
                    label={
                        <Flex alignItems={'center'}>
                            <Text mr="2px">{item.alt}</Text>
                            <ExternalLinkIcon />
                        </Flex>
                    }
                    motionProps={tooltipDownAnimation}
                    px="6px"
                    py="3px"
                    rounded="6px"
                >
                    <Button
                        as={Link}
                        href={item.url}
                        bg="transparent"
                        w="32px"
                        p="8px"
                        rounded="12px"
                    >
                        <Image
                            src={item.image}
                            width={32}
                            height={32}
                            alt={item.name}
                            quality={100}
                        />
                    </Button>
                </Tooltip>
            ))}
        </Flex>
    );
};

const ExternalLinkIcon = () => {
    const color = useColorModeValue('#FFFFFF70', '#484C4D');

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill={color}
            style={{ width: '16px', height: '16px' }}
        >
            <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
            <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
        </svg>
    );
};
