import { extendTheme, theme as baseTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const config = {
    initialColorMode: 'light',
    useSystemColorMode: false
};

const lightColors = {
    gray: {
        50: '#F4F5F7',
        100: '#EDF0F4',
        200: '#E6EAEF',
        300: '#D4D8DD',
        400: '#B0B7C1',
        500: '#828B9A',
        600: '#555D6C',
        700: '#353C49',
        800: '#1F242E',
        900: '#1A1B23'
    }
};

const darkColors = {
    gray: {
        50: 'red',
        100: 'green',
        200: 'blue',
        300: '#D4D8DD',
        400: '#B0B7C1',
        500: '#828B9A',
        600: '#555D6C',
        700: '#353C49',
        800: '#1F242E',
        900: '#1A1B23'
    }
};

const theme = extendTheme({
    colors: lightColors,
    components: {
        Button: {
            baseStyle: {
                //bg: 'linear-gradient(180deg, #F7F9FA 0%, #F1F4F8 54%, #E4EAF2 100%)',
                //border: 'var(--chakra-colors-teBorder)',
                transition: '0.1s'
            }
        },
        IconButton: {
            // Default props for the Button component
            defaultProps: {
                transition: '0.1s'
            }
        },
        Input: {
            defaultProps: {
                focusBorderColor: '#5ba6ff'
            }
        },
        Switch: {
            baseStyle: {
                track: {
                    _checked: {
                        bg: '#54a5fe'
                    }
                }
            }
        }
    },
    semanticTokens: {
        colors: {
            bg0: {
                default: 'gray.200',
                _dark: '#0e1013'
            },
            skeletonBg: {
                default: 'gray.200',
                _dark: '#252A2D'
            },
            te1: {
                default: 'gray.50',
                _dark: '#18191b'
            },
            te2: {
                default: 'gray.100',
                _dark: 'gray.700'
            },
            /* shadow0: {
                default: '-4px 0 16px 0 rgba(166,166,167,0.30)',
                _dark: '-4px 0 18px 0 rgba(19,20,24,0.95)'
            }, */
            groupBg: {
                default: '#FFF',
                _dark: '#1e2123'
            },
            mainButtonBg: {
                default: '#f4f5f7',
                _dark: '#41484F'
            },
            mainButtonShadow: {
                default:
                    '0 1px 2px 0 rgba(142,149,173,0.64),0 0px 1px 1px rgba(142,149,173,0.1), inset 1px 1px 1px 0 rgba(255,255,255,0.81), inset -1px -1px 1px 0 #E2E4E9',
                _dark: '0 1px 2px 0 rgba(3,4,5,0.64), inset 1px 1px 2px 0 rgba(154,160,164,0.40), inset -1px -1px 2px 0 #2A2F34'
            },
            wsPlateShadow: {
                default: 'drop-shadow(0px 4px 6px rgba(166,166,167,0.30))',
                _dark: 'drop-shadow(0px 4px 6px rgba(19,20,24,0.95))'
            },
            iconExtraLight: {
                default: 'gray.300',
                _dark: 'gray.200'
            },
            iconLight: {
                default: 'gray.400',
                _dark: 'gray.600'
            },
            iconMd: {
                default: 'gray.600',
                _dark: 'gray.400'
            },
            categoriesBg: {
                default: 'gray.200',
                _dark: '#262A2D'
            },
            categoriesSelectedBg: {
                default: 'linear-gradient(145deg, #5DABFE 0%, #3391FF 100%)',
                _dark: 'linear-gradient(178deg, #52595D 0%, #40484F 100%)'
            },
            catText: {
                default: 'gray.700', //'#454c59',
                _dark: 'gray.300'
            },
            catHoverText: {
                default: 'gray.800',
                _dark: 'gray.100'
            },
            secondaryText: {
                default: 'gray.600',
                _dark: 'gray.300'
            },
            emptyStateText: {
                default: 'gray.500',
                _dark: 'gray.300'
            },
            modalBg: {
                default: '#FFF',
                _dark: '#262A2D'
            },
            teBorder: {
                default: '1px solid #e4eaf0',
                _dark: '1px solid #394146'
            },
            popoverShadowLight: {
                default:
                    '0px 0px 1px rgba(166,166,167,0.52), 0px 10px 12px -2px rgba(166,166,167,0.4), 0px 24px 54px 3px rgba(166,166,167,0.28)',
                _dark: '0px 0px 1px rgba(19,20,24,0.95), 0px 10px 12px -2px rgba(19,20,24,0.74), 0px 24px 54px 3px rgba(19,20,24,0.65)'
            },
            noteBg: {
                default: 'gray.100',
                _dark: '#35393c'
            },
            inputBg: {
                default: 'gray.100',
                _dark: '#28292B'
            },
            hoverBg: {
                default: 'rgba(255,255,255,1)',
                _dark: 'rgba(30,33,35,1)'
            }
        }
    },
    ...config
});

export default theme;

//framer motion tooltip
export const tooltipAnimation = {
    initial: { opacity: 0, scale: 1, y: 4 },
    animate: { opacity: 1, scale: 1, y: 0 },
    exit: { opacity: 0, scale: 1, y: 4, transition: { duration: 0.06 } },
    transition: { opacity: { duration: 0.05 } }
};

export const tooltipDownAnimation = {
    initial: {
        opacity: 0,
        scale: 1,
        y: -4
    },
    animate: {
        opacity: 1,
        scale: 1,
        y: 0
    },
    exit: {
        opacity: 0,
        scale: 1,
        y: -4,
        transition: { duration: 0.08 }
    },
    transition: {
        opacity: { duration: 0.05 }
    }
};

export const slimScrollStyles = {
    '::-webkit-scrollbar': {
        width: '8px'
    },
    '::-webkit-scrollbar-thumb': {
        cursor: 'pointer',
        borderLeft: '0px solid transparent',
        borderRight: '4px solid transparent',
        borderTop: '8px solid transparent',
        borderBottom: '10px solid transparent',
        borderRadius: '4px',
        backgroundColor: 'transparent',
        transition: 'opacity 0.2s'
    }
};
